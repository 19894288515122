<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Office Permission">
      <template #page-header>
        <h2 class="page-title">Office Permission</h2>
        <r-menu-tab :level="3" />
      </template>

      <template #action-bar>
        <RequestHeader
          :filter="true"
          :search="true"
          :from="start_date"
          :to="end_date"
          :today="toDate"
          :checkedRows="checkedRows"
          :isMassUpdating="isMassUpdating"
          @onMassUpdate="massUpdate"
          @onSelectDate="selectDate"
          @clearDate="clearDate"
          :pageMenu="'request'"
          @onToggleFilterStatus="onToggleFilterStatus"
          :lodaData="isWFOPermissionApprovalListLoading"
          @onSearch="searchIconClick($event)"
        />
      </template>

      <template #page-content>
        <div
          id="table-request-employment"
          class="table-timeoff-type table-with-checklist"
        >
          <b-table
            :data="wfoPermissionApprovalData"
            :per-page="perPage"
            :loading="isWFOPermissionApprovalListLoading"
            @page-change="onPageChange"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :is-row-checkable="(row) => row.status === 'waiting'"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="request_id"
                label="Request ID"
                v-slot="props"
                sortable
                width="8%"
              >
                <span class="is-capitalize">
                  {{ props.row.requestId }}
                </span>
              </b-table-column>

              <b-table-column
                field="full_name"
                label="Full Name"
                v-slot="props"
                sortable
                width="23%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="log-activity-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.requestedUser.profilePictureUrl,
                        props.row.requestedUser.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.requestedUser.fullName }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="date"
                label="Date"
                v-slot="props"
                sortable
                width="12%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.date) }}
                </span>
              </b-table-column>

              <b-table-column
                field="type"
                label="Type"
                v-slot="props"
                sortable
                width="8%"
                cell-class="table-in-office-session"
              >
                <span :class="formatTypeClass(props.row.type)">
                  {{ formatType(props.row.type) }}
                </span>
              </b-table-column>

              <b-table-column
                field="shift"
                label="Session"
                v-slot="props"
                sortable
                width="8%"
                cell-class="table-in-office-session"
              >
                <span :class="formatSessionClass(props.row.shift)">
                  {{ formatSession(props.row.shift) }}
                </span>
              </b-table-column>

              <b-table-column
                field="requestReason"
                label="Purpose"
                v-slot="props"
                sortable
                width="22%"
              >
                <span class="is-capitalize">
                  {{ props.row.requestReason || '-' }}
                </span>
              </b-table-column>

              <b-table-column
                field="status"
                label="Status"
                v-slot="props"
                sortable
                width="7%"
              >
                <span
                  :class="`is-capitalize
                    ${determineFCByStatus(props.row.status)}`"
                >
                  {{ props.row.status }}
                </span>
              </b-table-column>

              <b-table-column
                v-slot="props"
                field="created_at"
                label="Created At"
                sortable
                width="12%"
              >
                <span>
                  {{ formatDate(props.row.createdAt) }}
                </span>
              </b-table-column>

              <b-table-column label="Action" v-slot="props" width="5%">
                <b-icon
                  type="is-primary"
                  icon="pencil"
                  custom-size="mdi-18px"
                  class="employment-edit-icon"
                  @click.native="openModalDetail(props.row)"
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <WFOPermissionManagementModal
          :show="isModalOpen"
          :selectedWFOPermission="selectedWFOPermission"
          :userApproval="userApproval"
          :disableDeclined="disableDeclined"
          :isUpdatingDeclined="isUpdatingDeclined"
          :disableAccepted="disableAccepted"
          :isUpdatingAccepted="isUpdatingAccepted"
          :disableCanceled="disableCanceled"
          @hide="closeModalDetail"
          @updateStatusApproval="updateStatusApproval"
        />
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment-timezone'
import {
  showToast,
  determineBackgroundColorByStatus,
  determineFontColorByStatus,
} from '@/services/util'
import RequestHeader from '../../../components/Header/RequestHeader.vue'
import WFOPermissionManagementModal from './WFOPermissionManagementModal.vue'

export default {
  components: { RequestHeader, WFOPermissionManagementModal },
  data() {
    return {
      moduleType: 6, // Office Permission
      stickyHeaders: true,
      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,
      checkedRows: [],

      defaultSortOrder: 'desc',
      sortField: 'created_at',
      sortOrder: 'desc',
      search: '',

      isWFOPermissionApprovalListLoading: false,
      isLoadMore: false,
      isModalOpen: false,
      isEditing: false,
      start_date: '',
      end_date: '',

      selectedWFOPermission: null,

      isUpdating: false,
      isUpdatingAccepted: false,
      isUpdatingDeclined: false,
      disableDeclined: false,
      disableAccepted: false,
      disableCanceled: false,
      isMassUpdating: false,

      isFilterOpen: false,

      // filter by date
      dates: [],
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      selectedStatus: 'selectedStatus',
      status: 'waiting',
    }
  },
  computed: {
    ...mapGetters({
      wfoPermissionApprovalData: 'wfoPermission/getWFOPermissionApprovalData',
      currentUser: 'auth/currentUser',
      userApproval: 'finalApproval/getUserApproval',
      wfoPermissionApprovalDetail:
        'wfoPermission/getWFOPermissionApprovalDetail',
    }),
  },
  methods: {
    ...mapActions({
      actionLoadWFOPermissionApproval:
        'wfoPermission/fetchWFOPermissionApprovalData',
      actionUpdateStatusApproval: 'wfoPermission/updateWFOPermissionApproval',
      actionMassUpdateStatusApproval:
        'wfoPermission/massUpdateWFOPermissionApproval',
      actionFetchUserApproval: 'finalApproval/fetchUserApproval',
      actionFetchWFOPermissionApprovalDetail:
        'wfoPermission/fetchWFOPermissionApprovalDetail',
    }),

    ...mapMutations({
      setWFOPermissionApprovalData:
        'wfoPermission/setWFOPermissionApprovalData',
      setWFOPermissionApprovalDetail:
        'wfoPermission/setWFOPermissionApprovalDetail',
    }),

    /**
     * Clear date detail
     */
    async clearDate() {
      this.start_date = ''
      this.end_date = ''
      await this.resetState()
    },

    /**
     * Select Date Range of table data
     * @param {Date} val - selected date range
     */
    async selectDate(val) {
      this.start_date = moment(val[0]).format('YYYY-MM-DD')
      this.end_date = moment(val[1]).format('YYYY-MM-DD')

      await this.resetState()
    },

    /**
     * Toggle Filter Status
     * @param {Array} filterStatus - Array of status filter
     */
    async onToggleFilterStatus(filterStatus) {
      this.selectedStatus = filterStatus?.length < 1 ? '' : 'selectedStatus'
      this.status = filterStatus
      await this.resetState()
    },

    /**
     * Determine Profile picture from database
     * @param {String} picture - Picture URL
     * @param {String} gender - gender string
     * used if no picture is present ('F'/ 'M')
     * @return {String} default gender picture url
     */
    determineProfilePicture(picture, gender) {
      if (!picture) {
        if (gender === 'F') {
          // female
          return '/images/default-profile-picture-female.png'
        }
        // male
        return '/images/default-profile-picture-male.png'
      }

      return picture
    },

    /**
     * Determine background color by status
     * @param {String} status - status
     * @return {String} background color css class
     */
    determineBGClassByStatus(status) {
      return determineBackgroundColorByStatus(status)
    },

    /**
     * Set table status color
     * @param {String} status - request status
     * @return determineFontColorByStatus method
     */
    determineFCByStatus(status) {
      return determineFontColorByStatus(status)
    },

    /**
     * Set Format Date
     * @param {Date} str - date string
     * @return {Date} formatted date
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     * Set Session format
     * @param {integer} data - ID session
     */
    formatSession(data) {
      if (data === 1) {
        return 'Morning'
      } else if (data === 2) {
        return 'Afternoon'
      } else if (data === 3) {
        return 'Full Day'
      }
    },

    /**
     * Set WFO Session css class
     * @param {integer} data - id session
     * @return {String} session class name
     */
    formatSessionClass(data) {
      if (data === 1) {
        return 'session-morning'
      } else if (data === 2) {
        return 'session-afternoon'
      } else if (data === 3) {
        return 'session-full-day'
      }
    },

    /**
     * Set WFO Type name
     * @param {String} data - wfo type name
     * @return {String} wfo type name
     */
    formatType(data) {
      data = parseInt(data)
      if (data === 1) {
        return 'WFO'
      } else if (data === 2) {
        return 'WFH'
      }
    },

    /**
     * Set WFO Type css class
     * @param {String} data - wfo type class
     * @return {String} wfo type name
     */
    formatTypeClass(data) {
      data = parseInt(data)
      if (data === 1) {
        return 'type-wfo'
      } else if (data === 2) {
        return 'type-wfh'
      }
    },

    /**
     * Load more data of office permission list
     */
    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadWFOPermissionApprovalList()
        this.isLoadMore = false
      }
    },

    /**
     * Search table data
     * @param {String} searchInput - keyword search
     */
    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    /**
     * Reset state of table when form is created, updated
     * @param {String} searchInput - keyword search (optional)
     */
    async resetState(searchInput) {
      this.page = 0
      this.lastPage = 0
      this.setWFOPermissionApprovalData([])
      await this.loadWFOPermissionApprovalList(searchInput)
    },

    /**
     * Sort table
     * @param {String} field - name of field
     * @param {String} order - asc or desc
     */
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    /**
     * Change page behavior
     * @param {integer} page - number of page
     */
    onPageChange(page) {
      this.page = page
      this.loadWFOPermissionApprovalList()
    },

    /**
     * Load WFO permission approval list data.
     * @param {String} searchInput - keyword search (optional)
     */
    async loadWFOPermissionApprovalList(searchInput) {
      this.isWFOPermissionApprovalListLoading = true

      try {
        const response = await this.actionLoadWFOPermissionApproval({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
          start_date: this.start_date,
          end_date: this.end_date,
          selectedStatus: this.selectedStatus,
          status: this.status,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isWFOPermissionApprovalListLoading = false
    },

    /**
     * Open Detail Modal. If id present,
     * set form to include data from selected table id
     * @param {integer} row - table row index (optional)
     * @param {integer} id - selected data id (optional)
     */
    async openModalDetail(row, id) {
      if (id) {
        let response = await this.actionFetchWFOPermissionApprovalDetail(id)
        this.selectedWFOPermission = response.data.data
      } else {
        this.selectedWFOPermission = row
      }
      this.isModalOpen = true
    },

    /**
     * Close detail modal
     */
    closeModalDetail() {
      this.selectedWFOPermission = null
      this.isModalOpen = false
    },

    /**
     * Update multiple request status
     * @param {Object} status object
     */
    async massUpdate(status) {
      let ids = []

      this.checkedRows.forEach((c) => ids.push(c.id))

      // trigger loading animation
      this.isMassUpdating = true

      try {
        await this.actionMassUpdateStatusApproval({ status, ids })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }
      this.isMassUpdating = false
      this.checkedRows = []
    },

    /**
     * Update Approval Status
     * @param {integer} id - selected data id
     * @param {String} status - status string
     */
    async updateStatusApproval(param) {
      const { id, status } = param
      const currentUserId = this.currentUser.id

      if (status === 'declined') {
        this.disableAccepted = true
        this.isUpdatingDeclined = true
      } else {
        this.disableDeclined = true
        this.isUpdatingAccepted = true
      }

      try {
        await this.actionUpdateStatusApproval({
          id,
          status,
          reportToId: currentUserId,
        })
        showToast('Update Success', 'is-success', 'is-top')
      } catch (err) {
        if (err.response.data.message) {
          showToast(err.response.data.message, 'is-danger', 'is-top')
        }
        console.log(err)
      }

      this.closeModalDetail()

      if (status === 'declined') {
        this.disableAccepted = false
        this.isUpdatingDeclined = false
      } else {
        this.disableDeclined = false
        this.isUpdatingAccepted = false
      }
    },
  },
  async mounted() {
    this.setWFOPermissionApprovalData([])
    await this.loadWFOPermissionApprovalList()
    if (this.$route.query.id) {
      await this.openModalDetail(null, this.$route.query.id)
    }
  },
}
</script>
